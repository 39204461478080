<template>
    <Page>
        <div class="grid grid-cols-12 gap-6 sm:pt-4">
            <CurrentBalance />
            <LeaseOverview />
        </div>
        <div v-if="totals.length && arePeriodsHasBalances">
            <div class="text-base uppercase font-bold font-ffdin text-gray-700 tracking-normal mt-8">
                {{ $t('payments.components.account_summury') }}
            </div>
            <AccountSummary
                :totals="totals"
                class="mt-8"
            />
        </div>
    </Page>
</template>

<script>
import AccountSummary from '@/components/payments/AccountSummary.vue';
import CurrentBalance from '@/components/dashboard/CurrentBalance.vue';
import LeaseOverview from '@/components/dashboard/LeaseOverview.vue';
import Page from '@/components/ui/Page';
import { mapGetters } from 'vuex';

export default {
    components: { Page, AccountSummary, CurrentBalance, LeaseOverview },

    computed: {
        ...mapGetters({
            totals: 'accounting/totals',
        }),

        arePeriodsHasBalances() {
            return this.totals?.some(period => +period.balance !== 0);
        },
    },
};
</script>
